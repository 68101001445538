import "./src/style.scss";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/lazy";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/zoom";

export const onRouteUpdate = ({ location, prevLocation }) => {

  // check if location.state is undefined and set it to an empty object
  if (!location.state) {
    location.state = {};
  }

  // set referrer value
  const referrer = document.referrer;
  location.state.referrer = referrer;

  //set prevPage value
  let prevPage = location.pathname;
  if (prevLocation) {
    prevPage = prevLocation.pathname;
    const newData = { prevPage: prevLocation.pathname };
    const data = JSON.parse(sessionStorage.getItem("locationState"));
    const updatedData = { ...data, ...newData };
    sessionStorage.setItem("locationState", JSON.stringify(updatedData));
  }

  // set UTM values
  let isUTMAlreadySet = false;
  if (!location.search) return;
  if (!isUTMAlreadySet) {
    const searchParams = new URLSearchParams(location.search);
    const utmSource = searchParams.get("utm_source");
    const utmMedium = searchParams.get("utm_medium");
    const utmCampaign = searchParams.get("utm_campaign");
    const utmTerm = searchParams.get("utm_term");
    const utmContent = searchParams.get("utm_content");
    location.state.utmSource = utmSource;
    location.state.utmMedium = utmMedium;
    location.state.utmCampaign = utmCampaign;
    location.state.utmTerm = utmTerm;
    location.state.utmContent = utmContent;
    isUTMAlreadySet = true;
  }

  // save location.state in sessionStorage
  if (typeof window !== "undefined" && typeof sessionStorage !== "undefined") {
    sessionStorage.setItem("locationState", JSON.stringify(location.state));
  }
};
